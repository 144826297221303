import { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress, TableFooter } from "@mui/material";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import ISubSkills from "../../interfaces/ISubSkills";
import { useLocation, useNavigate } from "react-router-dom";
import lessonSkillsService from "../../services/LessonSkillsService";

registerLocale("pt-BR", ptBR);

const SubSkillsList = (props: any) => {
  const [items, setItems] = useState<ISubSkills[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [currentSubSkill, setCurrentSubSkill] = useState<ISubSkills | null>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);  // Novo estado para criação

  const skillId = location.state?.idSkill;

  const loadList = () => {
    setLoading(true);
    lessonSkillsService
      .getSubSkillsBySkill(skillId)
      .then((response) => {
        const subSkills: ISubSkills[] = response.data.subSkills;
        setItems(subSkills);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching subSkills:", error);
      });
  };

  const handleShowUpdate = (subSkill: ISubSkills) => {
    setCurrentSubSkill(subSkill);
    setIsCreating(false);  // Não está criando, está atualizando
    setShow(true);
  };

  const handleShowCreate = () => {
    setCurrentSubSkill({ id: 0, description: '' });  // Resetando para nova criação
    setIsCreating(true);  // Está criando
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentSubSkill(null);
    setIsCreating(false);  // Resetando estado de criação
  };

  const handleSave = () => {
    if (currentSubSkill) {
      if (isCreating) {
        lessonSkillsService
          .createSubSkills({ description: currentSubSkill.description, skillId })  // Chamando o serviço de criação
          .then(() => {
            loadList();
            handleClose();
          })
          .catch((error) => {
            console.error("Error creating subSkill:", error);
          });
      } else {
        lessonSkillsService
          .updateSubSkill({ description: currentSubSkill.description }, currentSubSkill.id)
          .then(() => {
            loadList();
            handleClose();
          })
          .catch((error) => {
            console.error("Error updating subSkill:", error);
          });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSubSkill) {
      setCurrentSubSkill({
        ...currentSubSkill,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDelete = (id: number) => {
    lessonSkillsService
      .delete(id)
      .then((response) => {
        loadList();       
      })
      .catch((error) => {
        alert("Não foi possível excluir subSkill");
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const goToSkills = () => {
    navigate("/list-skills");
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="container-fluid main-container">
        <div className="top-bar">
          <div className="title">
            <h3>Lista de SubSkills</h3>
            
          </div>
          <div id="container-filters">
            <div className="top-bar-collor"></div>
          </div>
        </div>

        <Button className="btn btn-primary" onClick={handleShowCreate}>Criar SubSkill</Button>  
            
        <br /> <br />
        <Table className="tabela" striped bordered hover>
          <thead>
            <tr className="tr">
              <th style={{ textAlign: "center", fontSize: 25 }}>Descrição</th>
              <th style={{ textAlign: "center", fontSize: 25, width: 500 }}>Ação</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={6}>
                  <p>Carregando...</p>
                </td>
              </tr>
            )}
            {items.map((item: ISubSkills) => (
              <tr key={item.description}>
                <td>
                  <span>{item.description}</span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-primary"
                    style={{ marginRight: 10, marginLeft: 10, background:'#6B8E23'}}
                    onClick={() => handleShowUpdate(item)}
                  >
                    Atualizar
                  </button>

                  <button
                    className="btn btn-danger"
                    onClick={(e) => { handleDelete(item.id) }}
                  >
                    Excluir
                  </button>
                </td>
              </tr>
            ))}
            {items.length === 0 && !isLoading && (
              <tr>
                <td colSpan={2}>
                  <p>Nenhuma subSkill encontrada.</p>
                </td>
              </tr>
            )}
          </tbody>
          <TableFooter>
            {isLoading && (
              <tr className="tr">
                <th colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </th>
              </tr>
            )}
          </TableFooter>
        </Table>
        <div className="voltar">
          <button
            style={{ width: "100px" }}
            className="btn btn-primary"
            onClick={goToSkills}
          >
            Voltar
          </button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{isCreating ? 'Criar SubSkill' : 'Atualizar SubSkill'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDescription">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={currentSubSkill ? currentSubSkill.description : ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubSkillsList;
