import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Alert,
  FormCheck,
  InputGroup,
} from "react-bootstrap";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import 'react-indiana-drag-scroll/dist/style.css'
import ICourseCreate from "../../interfaces/ICourseCreate";
import courseService from "../../services/CourseService";

registerLocale("pt-BR", ptBR);

const ModalCourses = ({show, handleClose}:{show:boolean; handleClose:() => void}) => {
 
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [linkYoutube, setLinkYoutube] = useState<string>("");
  const [imageBanner, setImageBanner] = useState<string>("");   
  const [message, setMessage] = useState("");
  
  const handleClick = (e: React.FormEvent)=> {e.preventDefault();
   
    
    const data: ICourseCreate={
      name:name, 
      description:description,
      link_youtube:linkYoutube,
      img_banner:imageBanner,     
    }

   
    courseService
    .create(data)
    .then((response) => {
      setName("")
      setDescription("")
      setLinkYoutube("")
      setImageBanner("")
      handleClose();
    })
    .catch(() => {
      setError(true);
    });
  }   
    

  return (
    <Modal show={show} onHide={handleClose} size="xl">
    <Modal.Header closeButton>
      <Modal.Title>Cadastrar Curso</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form>
      <Form.Group className="mb-1">
          <Form.Label htmlFor="inputNameCustomer"><strong>Nome do curso</strong></Form.Label>
          <Form.Control  onChange={(e:any) => setName(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-1">
          <Form.Label htmlFor="inputNameCustomer"><strong>Descrição do Curso</strong></Form.Label>
          <Form.Control  onChange={(e:any) => setDescription(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-1">
          <Form.Label htmlFor="inputNameCustomer"><strong>Link do Youtube</strong></Form.Label>
          <Form.Control  onChange={(e:any) => setLinkYoutube(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-1">
          <Form.Label htmlFor="inputNameCustomer"><strong>Banner </strong></Form.Label>
          <Form.Control  onChange={(e:any) => setImageBanner(e.target.value)}/>
          </Form.Group>
      </Form>
      {error && (
        <Alert variant="danger" onClose={() => setError(false)} dismissible>
          Pergunta já cadastrada
        </Alert>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        onClick={() => {
          handleClose();
        
        }}
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        className="btn btn-primary"
        onClick={(e) => handleClick(e)}
      >
        Salvar
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default ModalCourses;
