import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import { TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";
import courseService from "../../services/CourseService";
import ICourse from "../../interfaces/ICourse";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ModalCourses from "../Cursos";

registerLocale("pt-BR", ptBR);

const CoursesList = () => {
  const [items, setItems] = useState<ICourse[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [userId, setUserId] = useState<number>();
  const [name, setName] = useState<string>();  
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  


  const navigate = useNavigate();



  const goToLesson = (course: ICourse) => {
    navigate("/list-lessons", { state: { idCourse: course.id } });
  };

  const loadList = () => {
    setLoading(true);
      courseService
      .getAll()
      .then((response) => {
        let courses: ICourse[] = response.data;
        setItems(courses);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(()=>{if(show === false){loadList()}},[show])



 

  const handleClose = () => {
   

    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    
  };

  const handleDelete = (id: number) => {
    courseService
      .delete(id)
      .then((response) => {
        loadList();       
      })
      .catch((error) => {
        alert("Não foi possível excluir Curso");
      });
  };
  
  useEffect(() => {
    loadList();
  }, []);


  return (
    <>
      <div style = {{overflowX: 'hidden'}}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Lista de Cursos</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow} >
              Cadastrar Curso
            </Button>
          </div>

          <br />
          <Table
            className="tabela"
            striped
            bordered
            hover
            width={"100%"}
          >
            <thead>
              <tr className="tr">
                <th  style={{ textAlign:"center", fontSize: 25}}>Cursos</th>               
                <th style={{ textAlign:"center", fontSize: 25, width: 200}}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((item: ICourse) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                  </td>                 

                  <td style={{ textAlign: "center" }}>

                    <div className="buttonLesson">
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={() => goToLesson(item)}                   
                    >
                      Aulas
                    </button>

                    <button className="btn btn-danger"  onClick={(e) => {handleDelete(item.id)}}                  
                     
                    >
                      Excluir
                    </button>
                    </div>

                    
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={2}>
                    <p>Nenhum curso encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>

            
            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={3}>
                    <LinearProgress variant="indeterminate"                
                    />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
        </div>
      </div>
      
       <ModalCourses {...{show, handleClose}} />
    </>
  );
};

export default CoursesList;
