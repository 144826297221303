import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import { TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";
import courseService from "../../services/CourseService";
import ICareerPathCreate from "../../interfaces/ICareerPathCreate";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ModalCourses from "../Cursos";
import careerPathService from "../../services/CareerPathService";
import ModalCareerPath from "../CareerPathModal";
import ICareerPath from "../../interfaces/ICareerPath";
import ModalCareerLessons from "../ModalCareerLessons";
import { set } from "date-fns";

registerLocale("pt-BR", ptBR);

const CareerPath = () => {
  const [items, setItems] = useState<ICareerPath[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [userId, setUserId] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [show, setShow] = useState<boolean>(false);

  const navigate = useNavigate();

  const goToCoursesPath = (careerPath: ICareerPath) => {
    navigate("/career-course", { state: { idCareer: careerPath.id } });
  };

  const loadList = () => {
    setLoading(true);
    careerPathService
      .getAll()
      .then((response) => {
        let careerPath: ICareerPath[] = response.data;
        setItems(careerPath);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show === false) {
      loadList();
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleDelete = (id: number) => {
    careerPathService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        alert("Não foi possível excluir Curso");
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Trilhas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar Trilha
            </Button>
          </div>

          <br />
            <Table
              className="tabela"
              striped
              bordered
              hover
              style={{ width: "100%" }}
            >
              <thead>
                <tr className="tr">
                  <th style={{ textAlign: "center", fontSize: 25 }}>
                    Trilha de Cursos
                  </th>
                  <th style={{ textAlign: "center", fontSize: 25, width: 200 }}>
                    Ação
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                )}
                {items.map((item: ICareerPath) => (
                  <tr key={item.title}>
                    <td>
                      <span>{item.title}</span>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <div className="buttonLesson">
                        <button
                          className="btn btn-primary"
                          style={{ marginRight: 10, marginLeft: 10 }}
                          onClick={() => goToCoursesPath(item)}
                        >
                          cursos
                        </button>

                        <button
                          className="btn btn-danger"
                          onClick={(e) => {
                            handleDelete(item.id);
                          }}
                        >
                          Excluir
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {items.length === 0 && !isLoading && (
                  <tr>
                    <td colSpan={2}>
                      <p>Nenhuma trilha encontrada.</p>
                    </td>
                  </tr>
                )}
              </tbody>

              <TableFooter>
                {isLoading && (
                  <tr className="tr">
                    <th colSpan={3}>
                      <LinearProgress variant="indeterminate" />
                    </th>
                  </tr>
                )}
              </TableFooter>
            </Table>
        </div>
      </div>

      <ModalCareerPath {...{ show, handleClose }} />
    </>
  );
};

export default CareerPath;
