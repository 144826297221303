import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import ISkill from "../../interfaces/ISkill";
import skillService from "../../services/SkillService";
import { SkillType } from "../../enums/SkillType";

const ModalEditSkill = ({ show, handleClose, skill }: { show: boolean, handleClose: () => void, skill: ISkill }) => {
  const [name, setName] = useState<string>(skill.name);
  const [description, setDescription] = useState<string>(skill.description);
  const [type, setType] = useState<SkillType>(SkillType.HARD);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setName(skill.name);
    setDescription(skill.description);
    setType(skill.type);
  }, [skill]);

    const handleTypeChange = (e: React.ChangeEvent<unknown>) => {
    setType((e.target as HTMLSelectElement).value as SkillType);
  };

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const updateSkill: ISkill = { ...skill, name, description, type };
      //console.log('o que tá vindo',updateSkill)
      await skillService.updateSkill(updateSkill, skill.id);
      handleClose();
    } catch (error) {
      setError(true);
    }
  };

  

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Editar Skill</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleUpdate}>
          <Form.Group className="mb-3">
            <Form.Label>Nome da Skill</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descrição da Skill</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              value={type}
              onChange={handleTypeChange}
            >
              <option value={SkillType.HARD}>HARD</option>
              <option value={SkillType.SOFT}>SOFT</option>
            </Form.Select>
          </Form.Group>
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Falha ao atualizar skill. Tente novamente.
            </Alert>
          )}
          <Button variant="primary" type="submit"
          
          >
            Salvar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditSkill;
