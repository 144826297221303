import "./App.css";
import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import api from "./services/api";
import tokenService from "./services/TokenService";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import AdminRoute from "./components/adminRoute/AdminRoute";
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import AppLayout from "./components/layout/AppLayout";
import Menu from "./pages/Menu";
import QuizQuestionsList from "./pages/QuizQuestions";
import CoursesList from "./pages/ListCourses";
import LessonsList from "./pages/ListLessons";
import CareerPath from "./pages/CareerPath";
import CareerCourses from "./pages/PathCourses";
import QuestionList from "./pages/ListQuestion";
import SkillsList from "./pages/ListSkills";
import SubSkillsList from "./pages/ListSubSkills";
import DevRegionList from "./pages/ListDevRegion";
import LessonsListRelated from "./pages/ListLessonsRelated";


function App() {
  useEffect(() => {
    api.interceptors.request.use(async (config: any) => {
      if (config.url !== "/user/login") {
        let token = window.sessionStorage.getItem("hash");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      }
      return config;
    });

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          // Inverter o if
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config._retry &&
            !err.config.url.endsWith("login")
          ) {
            originalReq._retry = true;
            let token = window.sessionStorage.getItem("hash");
            if (token === null) return;
            let res = tokenService
              .refresh({ oldToken: token })
              .then((res) => {
                console.clear();
                if (res.data.access_token) {
                  window.sessionStorage.setItem("hash", res.data.access_token);
                }
                originalReq.headers[
                  "Authorization"
                ] = `Bearer ${res.data.access_token}`;
                return axios(originalReq);
              })
              .catch((error) => console.log(error));
            resolve(res);
          } else {
            reject(err);
          }
        });
      }
    );
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/menu" element={<AdminRoute element={<QuestionList />} />} />

          <Route
            path="/list-users"
            element={<AdminRoute element={<UserList />} />} />

          <Route
            path="/list-quiz-questions"
            element={<AdminRoute element={<QuizQuestionsList />} />} />

          <Route
            path="/list-lessons-related"
            element={<AdminRoute element={<LessonsListRelated />} />} />

          <Route
            path="/list-question"
            element={<AdminRoute element={<QuestionList />} />} />


          <Route
            path="/list-courses"
            element={<AdminRoute element={<CoursesList />} />} />

          <Route
            path="/list-courses"
            element={<AdminRoute element={<CoursesList />} />} />

          <Route
            path="/list-sub-skills"
            element={<AdminRoute element={<SubSkillsList />} />} />

            <Route
            path="/list-dev-region"
            element={<AdminRoute element={<DevRegionList />} />}  />


          <Route
            path="/list-skills"
            element={<AdminRoute element={<SkillsList />} />} />


          <Route
            path="/career-path"
            element={<AdminRoute element={<CareerPath />} />} />

          <Route
            path="/career-course"
            element={<AdminRoute element={<CareerCourses />} />} />



          <Route
            path="/list-lessons"
            element={<AdminRoute element={<LessonsList />} />} />

        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
