import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import { TableFooter } from "@mui/material";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";
import courseService from "../../services/CourseService";
import ICourse from "../../interfaces/ICourse";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ModalCourses from "../Cursos";
import ISkill from "../../interfaces/ISkill";
import lessonSkillsService from "../../services/LessonSkillsService";
import ISubSkills from "../../interfaces/ISubSkills";
import ModalCreateSkill from "../CreateSkillModal";
import ModalEditSkill from "../ModalEditSkill";
import skillService from "../../services/SkillService";

registerLocale("pt-BR", ptBR);

const SkillsList = () => {
  const [items, setItems] = useState<ISkill[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>();  
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false); 
  const [error, setError] = useState<string | null>(null);
  const [currentSkill, setCurrentSkill] = useState<ISkill>({} as ISkill);
  const navigate = useNavigate();

  const goToSubSkills = (skill: ISkill) => {
    navigate("/list-sub-skills", { state: { idSkill: skill.id } });
  };

  const loadList = () => {
    setLoading(true);
    lessonSkillsService
      .getSkills()
      .then((response) => {
        setError(null);
        let skills: ISkill[] = response.data;
        setItems(skills);
        setLoading(false);
      })
      .catch((error) => {
        setError("Não foi possível carregar a lista de skills");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!showCreateModal && !showEditModal) {
      loadList();
    }
  }, [showCreateModal, showEditModal]);

  const handleShowCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreate = () => {
    setShowCreateModal(false);
  };

  const handleShowEdit = (skill: ISkill) => {
    setCurrentSkill(skill);
    setShowEditModal(true);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
  };

  const handleDelete = (id: number) => {
    courseService
      .delete(id)
      .then(() => {
        loadList();
      })
      .catch(() => {
        setError("Não foi possível excluir o curso");
      });
  };

  const handleDeleteSkill = (id: number) => {
    skillService
      .delete(id)
      .then((response) => {
        loadList();       
      })
      .catch((error) => {
        alert("Não foi possível excluir Skill");
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Lista de Skills</h3>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShowCreate}>
              Cadastrar Skills
            </Button>
          </div>
          <br />
          {error && <Alert variant="danger">{error}</Alert>}
          <Table className="tabela" striped bordered hover>
            <thead>
              <tr className="tr">
                <th style={{ textAlign: "center", fontSize: 25 }}>Skills</th>
                <th style={{ textAlign: "center", fontSize: 25 }}>Descrição</th>
                <th style={{ textAlign: "center", fontSize: 25 }}>Type</th>
                <th style={{ textAlign: "center", fontSize: 25, width: 500 }}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {!isLoading && items.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <p>Nenhum skill encontrado.</p>
                  </td>
                </tr>
              )}
              {!isLoading && items.map((item: ISkill) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.name}</span>
                  </td>
                  <td>
                    <span>{item.description}</span>
                  </td>
                  <td>
                    <span >{item.type}</span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div className="buttonLesson">
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px", background: "#6B8E23" }}
                        onClick={() => handleShowEdit(item)}
                      >
                        Atualizar
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => goToSubSkills(item)}
                      >
                        SubSkills
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteSkill(item.id)}
                      >
                        Excluir
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={4}>
                    <LinearProgress variant="indeterminate" />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
        </div>
      </div>
      <ModalCreateSkill show={showCreateModal} handleClose={handleCloseCreate} />
      <ModalEditSkill show={showEditModal} handleClose={handleCloseEdit} skill={currentSkill} />
    </>
  );
};

export default SkillsList;
