import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import 'react-indiana-drag-scroll/dist/style.css'
import ISkill from "../../interfaces/ISkill";
import lessonSkillsService from "../../services/LessonSkillsService";
import skillService from "../../services/SkillService";

registerLocale("pt-BR", ptBR);

enum SkillType {
  HARD = "HARD",
  SOFT = "SOFT",
}

const ModalCreateSkill = ({show, handleClose}:{show:boolean; handleClose:() => void}) => {
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<SkillType>(SkillType.HARD);
  const [message, setMessage] = useState("");

  const handleTypeChange = (e: React.ChangeEvent<unknown>) => {
    setType((e.target as HTMLSelectElement).value as SkillType);
  };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    const data: ISkill = {   
      id: 0, 
      name: name,
      lessonId:0,
      description: description,
      type: type
    };

    skillService.create(data)
      .then((response) => {
        setName("");
        setDescription("");
        setType(SkillType.HARD);
        handleClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Cadastrar Skill</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-1">
            <Form.Label htmlFor="inputNameCustomer"><strong>Nome da Skill</strong></Form.Label>
            <Form.Control
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label htmlFor="inputDescription"><strong>Descrição da Skill</strong></Form.Label>
            <Form.Control
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label htmlFor="inputType"><strong>Tipo</strong></Form.Label>
            <Form.Control
              as="select"
              value={type}
              onChange={handleTypeChange}
            >
              <option value={SkillType.HARD}>HARD</option>
              <option value={SkillType.SOFT}>SOFT</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {error && (
          <Alert variant="danger" onClose={() => setError(false)} dismissible>
            Ocorreu um erro ao cadastrar a Skill
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button type="submit" className="btn btn-primary" onClick={handleClick}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCreateSkill;
