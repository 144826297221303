import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Button, Form } from "react-bootstrap";
import loginService from "../../services/LoginService";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "inspector";

const Login = () => {
  const location = useLocation();
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [path, setPath] = useState(location.state?.path);
  const navigate = useNavigate();


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let data = {
      username: usuario,
      password: password,
    };

    loginService
      .login(data)
      .then((response) => {
        if(response.data.role==="admin"){
        const hash = response.data.access_token;
        window.sessionStorage.setItem("hash", hash);
        const role = response.data.role;
        window.sessionStorage.setItem("role", role);
        if (path) {
          navigate(path);
        } else {
          navigate("/menu");
        }
      }else{
        alert('Usuário deve ser Admin')
      }
      })
      .catch((error) => {
        alert("Usuário não encontrado");
      });
  };




  return (
    <>
      <div className="form-div">
        <div className="form-header">
          <h1 className="header-login">Adm Talent</h1>
        </div>
        <div className="form-body"
       
        >
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-5">
              <Form.Control
                type="text"
                placeholder="digite seu email admin"
                required
                onChange={(e) => setUsuario(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Control
                type="password"
                required
                placeholder="digite sua senha"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" className="button-send ">
              Entrar
            </Button>  
           
            
            
          </Form>
        </div>
        <div className="form-footer"></div>
      </div>
    </>
  );
};
export default Login;
