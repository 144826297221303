import api from "./api"

class QuizQuestionsService{

    async getAll(): Promise<any>{   
        return api.get(`/quiz-questions/all`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    

 

    async getAllByLesson(id:number): Promise<any>{   
        return api.get(`/quiz-questions/all-by-lesson/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async create(data: any): Promise<any>{
        return api({
            url: "/quiz-questions/create",
            method: "POST",
            timeout: 5000,
            data: data,
            headers: {"Content-Type": "multipart/form-data"}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async update(data: any, id: any): Promise<any>{
        return api({
            url: `/quiz-questions/update/${id}`,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async delete(id: any): Promise<any>{   
        return api.delete(`/quiz-questions/delete/${id}`).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}
        
const quizQuestionService = new QuizQuestionsService()
export default quizQuestionService;
