import api from "./api";


class CourseService {
  async getAll(): Promise<any> {
    return api.get("/courses/all-admin")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async create(data: any): Promise<any>{
    return api({
        url: "/courses/create",
        method: "POST",
        timeout: 5000,
        data: data,
        
    }).then((result) => {
        return Promise.resolve(result)
    }).catch((error) => {
        return Promise.reject(error)
    })
}

async delete(idCourse: number): Promise<any>{   
  return api.delete(`courses/${idCourse}`).then((result) => {
      return Promise.resolve(result)
  }).catch((error) => {
      return Promise.reject(error)
  })
}





}

const courseService = new CourseService();
export default courseService;
