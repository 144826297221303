import ICreateComment from "../interfaces/ICreateComment";
import api from "./api";

interface ICompleteLesson {
    id_lesson: number | undefined;
}

class LessonRelatedService {
    async getAll(idCourse: number): Promise<any> {
        return api
            .get(`/lessons/${idCourse}/all`)
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });

        // return Promise.resolve ({status: true})
    }

    async createMany(data: any): Promise<any> {
        return api({
            url: "/lessons-related/create-many",
            method: "POST",
            timeout: 5000,
            data: data,
        })
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async create(data: any): Promise<any> {
        return api({
            url: "/lessons-related/create",
            method: "POST",
            timeout: 5000,
            data: data,
        })
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }


    async deleteMany(data: number[]): Promise<any> {
        return api({
            url: "/lessons-related/delete-many",
            method: "DELETE",
            timeout: 5000,
            data: data,
        })
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async delete(idLessonRelated: number): Promise<any> {
        return api({
            url: "/lessons-related/delete/" + idLessonRelated,
            method: "DELETE",
            timeout: 5000,
        })
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async getAllUnawnseredQuestion(): Promise<any> {
        return api
            .get(`/questions/find-all`)
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });

        // return Promise.resolve ({status: true})
    }


    /* 24-07-24  */
    async getAllquestion(): Promise<any> {
        return api
            .get(`/comments/comments-student-admin`)
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });

        // return Promise.resolve ({status: true})
    }

    async comment(data: ICreateComment) {
        let token = localStorage.getItem("TOKEN");
        return api
            .post("/comments/create", data)
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async sendMailClass() {
        let token = localStorage.getItem("TOKEN");
        return api
            .post("/lessons/send-mail-new-lesson")
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    async linkLessons(data: any): Promise<any> {
        return api({
            url: "/lessons/create",
            method: "POST",
            timeout: 5000,
            data: data,
        })
            .then((result) => {
                return Promise.resolve(result);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }



}

const lessonRelatedService = new LessonRelatedService();
export default lessonRelatedService;
