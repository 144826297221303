import { ICommentResponse, IReplayAdmin } from "../interfaces/ICommentResponse";
import ICreateComment from "../interfaces/ICreateComment";
import api from "./api";

interface ICompleteLesson {
  id_lesson: number | undefined;
}

class LessonService {
  async getAll(idCourse: number): Promise<any> {
    return api
      .get(`/lessons/${idCourse}/all`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }

  async getAllWithLessonRelated(idLessonMain: number): Promise<any> {
    return api
      .get(`/lessons/${idLessonMain}/all-with-lesson-related`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async create(data: any): Promise<any> {
    return api({
      url: "/lessons/create",
      method: "POST",
      timeout: 5000,
      data: data,
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  async delete(idCourse: number): Promise<any> {
    return api
      .delete(`/lessons/${idCourse}`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllUnawnseredQuestion(): Promise<any> {
    return api
      .get(`/questions/find-all`)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }


  /* 29-07-24  */
  async getCommentsAfterLastAdmin(): Promise<IReplayAdmin> {
    return api
      .get(`/comments/after-last-admin`)
      .then((result) => {
        // Supondo que a resposta tenha a estrutura { data: ICommentResponse[] }
        return Promise.resolve(result.data); 
      })
      .catch((error) => {
        // Manipule o erro conforme necessário
        console.error('Erro ao obter comentários após o último administrador:', error);
        return Promise.reject(error); 
      });
  }


  async comment(data: ICreateComment) {
    let token = localStorage.getItem("TOKEN");
    console.log('Enviando dados para o backend:', data);
    return api
    .post("/comments/create", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async sendMailClass() {
    let token = localStorage.getItem("TOKEN");
    return api
      .post("/lessons/send-mail-new-lesson")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }



}

const lessonService = new LessonService();
export default lessonService;
