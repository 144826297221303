import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import lessonService from "../../services/LessonService";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLessons from "../Lessons";
import lessonRelatedService from "../../services/LessonRelatedService";
import { ILessonWithLessonRelated } from "../../interfaces/ILesson";

registerLocale("pt-BR", ptBR);

const ListLessonsRelated = (props: any) => {
  const [items, setItems] = useState<ILessonWithLessonRelated[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const location = useLocation();
  const [lessonId, setLessonId] = useState<number>(location.state?.idLesson);
  const [courseId, setCourseId] = useState<number>(location.state?.courseId);
  const [checked, setChecked] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  const loadList = () => {
    setLoading(true);
    if (courseId) {
      lessonService
        .getAllWithLessonRelated(lessonId)
        .then((response) => {
          let lessons: ILessonWithLessonRelated[] = response.data;
          setItems(lessons);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleDelete = (id: number) => {
    lessonService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        console.log("Não foi possível excluir aula");
      });
  };

  useEffect(() => {
    loadList();
  }, [courseId]);

  const goToQuestions = (idLesson: number) => {
    navigate("/list-quiz-questions", { state: { idLesson, courseId } });
  };

  const goToLessons = () => {
    navigate("/list-lessons", { state: { idCourse: courseId } });
  };

  const goToLessonsRelated = (idLesson: number) => {
    navigate("/list-lessons-related", { state: { idLesson, courseId } });
  };

  const handleLinkLessons = (idLessonAux: number) => {
    let data = {
      id_lesson_main: lessonId,
      id_lesson_aux: idLessonAux,
    }
    lessonRelatedService
      .create(data)
      .then(() => {
        console.log("Aula vinculada com sucesso");
        loadList();
      })
      .catch(() => alert("Erro ao vincular aula"));
  };

  const handleDeleteLessons = (idLessonRelated: number) => {
    lessonRelatedService
      .delete(idLessonRelated)
      .then(() => {
        console.log("Aula desvinculada com sucesso");
        loadList();
      })
      .catch(() => alert("Erro ao desvincular aula"));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (!show) {
      loadList();
    }
  }, [show]);

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Lista de aulas relacionadas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-flex gap-3 mb-3 align-items-center">
            <div>
              <button
                style={{ width: '100px' }}
                className="btn btn-primary"
                onClick={goToLessons}
              >
                Voltar
              </button>
            </div>
            <div className="container-search">
              {/* <Form.Group controlId="search">
                <Form.Control
                  type="text"
                  placeholder="Buscar nome da aula..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form.Group> */}

              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={filteredItems
                  .filter((item: ILessonWithLessonRelated) => !item.idLessonRelated)
                  .map((item: ILessonWithLessonRelated, index: number) => ({
                    label: item.name,
                    value: item.id,
                  }))
                }
                getOptionLabel={(option: string | { label: string; value: number; }) => (typeof option === 'string' ? option : option.label)}
                renderOption={(props, option) => (
                  <React.Fragment {...props}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #e5e5e5', margin: '15px', padding: '10px 0px 10px 0px' }}>
                      {option.label}
                      <Button
                        size="sm"
                        variant="success"
                        onClick={() => handleLinkLessons(option.value)}
                      >
                        Vincular
                      </Button>
                    </div>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: 900 }}
                    label="Buscar nome da aula..."
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </div>
          </div>

          <Table
            className="tabela"
            striped
            bordered
            hover
          >
            <thead>
              <tr className="tr">
                <th style={{ textAlign: "center", fontSize: 25 }}>Aulas</th>
                <th style={{ textAlign: "center", fontSize: 25 }}>
                  Descrição
                </th>
                <th style={{ textAlign: "center", fontSize: 25, width: 500 }}>
                  Ação
                </th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {filteredItems
                .filter((item) => item.idLessonRelated !== null)
                .map((item: ILessonWithLessonRelated, index: number) => (
                  <tr key={item.id}>
                    <td>
                      <span>{item.name}</span>
                    </td>

                    <td>
                      <span>{item.description}</span>
                    </td>

                    <td style={{ textAlign: "center", alignContent: "center" }}>
                      <Button className="btn btn-danger ms-2" onClick={() => { handleDeleteLessons(item.idLessonRelated!) }}>
                        Desvincular
                      </Button>
                    </td>
                  </tr>
                ))}

              {filteredItems.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={3}>
                    <p>Nenhuma aula encontrada.</p>
                  </td>
                </tr>
              )}
            </tbody>

            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
        </div>
      </div>

      <ModalLessons {...{ show, handleClose, courseId }} />
    </>
  );
};

export default ListLessonsRelated;
