import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import careerPathService from "../../services/CareerPathService";
import ICareerCourseDTO from "../../interfaces/ICareerCourseDto";


registerLocale("pt-BR", ptBR);

const ModalCareerPath = ({ show, handleClose }: { show: boolean; handleClose: () => void }) => {
  const [error, setError] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    const data: ICareerCourseDTO = {
      title: title,
      
      

    };

    careerPathService
      .create(data) // Utilizando o DTO
      .then((response) => {
        setTitle("");
        handleClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Cadastrar Trilha</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-1">
            <Form.Label htmlFor="inputNameCustomer"><strong>Título</strong></Form.Label>
            <Form.Control
              onChange={(e: any) => setTitle(e.target.value)}
              value={title}
            />
          </Form.Group>
        </Form>
        {error && (
          <Alert variant="danger" onClose={() => setError(false)} dismissible>
            Trilha já cadastrada
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => handleClick(e)}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCareerPath;
