import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import { TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";

registerLocale("pt-BR", ptBR);

const UserList = () => {
  const [items, setItems] = useState<IUser[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [userId, setUserId] = useState<number>();
  const [name, setName] = useState<string>();

  const [year, setYear] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordCompare, setPasswordCompare] = useState<string>();

  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const loadList = () => {
    setLoading(true);
    userService
      .getAll()
      .then((response) => {
        let users: IUser[] = response.data;
        setItems(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  // const handleShowUpdate = (user: IUser) => {
  //   setUserId(user.id);
  //   setName(user.name);
  //   setEmail(user.email);
  //   setYear(user.classroom);
  //   setSchool(user.school?.id);
  //   setType(user.type);
  //   setPhone(user.phone);
  //   setPassword("");
  //   setPasswordCompare("");
  //   handleShow();
  // };

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (email && emailRegex.test(email)) {
      if (password === passwordCompare) {
        let data = {
          name: name,
          email: email,
          password: password,
        };

        userService
          .create(data)
          .then((response) => {
            loadList();
            handleClose();
          })
          .catch(() => {
            setError(true);
          });
      } else {
        setPassError(true);
      }
    } else {
      setEmailError(true);
    }
  };

  const handleDelete = async (id: number) => {
    userService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        alert(
          "Não foi possível excluir o usuário porque possui dados vinculados no sistema"
        );
      });
  };

  const handleClose = () => {
    setName(undefined);
    setPassword(undefined);
    setEmail(undefined);

    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
    setPassError(false);
  };

  const handleCloseUsers = () => {
    setShowUsers(false);
  };

  const getUserType = (type: string) => {
    const types: { [key: string]: string } = {
      ADMIN: "Administrador",
      STUDENT: "Estudante",
    };
    return types[type];
  };

  return (
    <>
      <div style = {{overflowX: 'hidden'}}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Usuários Admin</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar usuário
            </Button>
          </div>

          <br />
          <Table
            className="tabela"
            striped
            bordered
            hover
            style={{ width: "100%" }}
          >
            <thead>
              <tr className="tr">
                <th>Nome</th>
                <th>Email</th>
                <th style={{ width: 150}}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((user: IUser) => (
                <tr key={user.id}>
                  <td>
                    <span>{user.name}</span>
                  </td>                   
                  <td>
                    <span>{user.email}</span>
                  </td>

                  <td style={{ textAlign: "center" }}>
                    {/* <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(user)}
                    >
                      Atualizar
                    </button> */}
                    <button className="btn btn-danger"                     
                      onClick={(e) => handleDelete(user.id)}
                    >
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum usuário encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>

            
            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Confirme a senha
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={passwordCompare}
                onChange={(e) => setPasswordCompare(e.target.value)}
              />
            </Form.Group>
          </Form>
          {passError && (
            <Alert
              variant="danger"
              onClose={() => setPassError(false)}
              dismissible
            >
              As senhas não coincidem
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Usuário já cadastrado
            </Alert>
          )}
          {emailError && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              E-mail inválido
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showUsers} onHide={handleCloseUsers}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUsers}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserList;
