import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress, TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import careerPathService from "../../services/CareerPathService";
import ICoursePath from "../../interfaces/ICoursePath";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCareerLessons from "../ModalCareerLessons";

registerLocale("pt-BR", ptBR);

const CareerCourses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [careerId, setCareerId] = useState<number>(location.state?.idCareer || 0);
  const [items, setItems] = useState<ICoursePath[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<ICoursePath | null>(null);
  const [updatedCourseName, setUpdatedCourseName] = useState<string>('');
  const [updatedCourseOrder, setUpdatedCourseOrder] = useState<number>(0);

  const goToTrilhas = () => {
    navigate("/career-path");
  };

  const loadList = () => {
    setLoading(true);
    careerPathService
      .getAllCareerCourses(careerId)
      .then((response) => {
        setItems(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, [show]);

  const handleClose = () => {
    setShow(false);
    loadList();
  };

  const handleShow = () => {
    setShow(true);
  };

  // const handleDelete = (id: number) => {
  //   courseService
  //     .delete(id)
  //     .then(() => {
  //       loadList();
  //     })
  //     .catch(() => {
  //       alert("Não foi possível excluir o curso.");
  //     });
  // };

  const handleUpdate = () => {
    if (selectedCourse) {
      // Removendo a propriedade `order` do objeto, se ela não fizer parte da entidade
      const updatedCourse = {
        id: selectedCourse.id,
        order: updatedCourseOrder ,
        nameCourse: updatedCourseName,
        idCourse: selectedCourse.course.id       
      };

      careerPathService
        .updateCourse(updatedCourse, selectedCourse.id)
        .then(() => {
          alert("Curso atualizado com sucesso!");
          setShowEditModal(false);
          loadList();
        })
        .catch((error:any) => {         
          alert("Erro ao atualizar o curso: " + error.response.data.errorMessage);
        });
    }
  };

  const openEditModal = (course: ICoursePath) => {
    setSelectedCourse(course);
    setUpdatedCourseName(course.course.name);
    setUpdatedCourseOrder(course.order);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedCourse(null);
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Lista de Cursos</h3>
            </div>
            <div id="container-filters">
              <div className="top-bar-color"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar Curso
            </Button>
          </div>

          <br />
          <Table className="tabela" striped bordered hover>
            <thead>
              <tr className="tr">
                <th style={{ textAlign: "center", fontSize: 25 }}>Cursos</th>
                <th style={{ textAlign: "center", fontSize: 25 }}>Ordem</th>
                <th style={{ textAlign: "center", fontSize: 25, width: 200 }}>Ação</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={3}>
                    <LinearProgress />
                  </td>
                </tr>
              ) : (
                items.map((item: ICoursePath) => (
                  <tr key={item.order}>
                    <td>{item.course.name}</td>
                    <td>{item.order}</td>
                    <td style={{ textAlign: "center" }}>
                      <div className="buttonLesson">
                        <Button
                          className="btn btn-primary"
                          style={{ marginRight: 10 }}
                          onClick={() => openEditModal(item)}
                        >
                          Atualizar
                        </Button>
                        <Button
                          className="btn btn-danger"
                          // onClick={() => handleDelete(item.course.id)}
                        >
                          Excluir
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={3}>
                    <p>Nenhum curso encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>
            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
          <div className="voltar">
            <Button
              style={{ width: 200 }}
              className="btn btn-primary"
              onClick={goToTrilhas}
            >
              Voltar
            </Button>
          </div>
        </div>
      </div>

      <ModalCareerLessons {...{ show, handleClose, idCareer: careerId }} />

      <Modal show={showEditModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Atualizar Curso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCourseName">
              <Form.Label>Nome do Curso</Form.Label>
              <Form.Control
                type="text"
                value={updatedCourseName}
                onChange={(e) => setUpdatedCourseName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCourseOrder">
              <Form.Label>Ordem do Curso</Form.Label>
              <Form.Control
                type="number"
                value={updatedCourseOrder}
                onChange={(e) => setUpdatedCourseOrder(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModalClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CareerCourses;
