import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import lessonService from "../../services/LessonService";
import moment from "moment";
import CommentForm from "./CommentForm";
import { ICommentResponse, IQuestionWithComments } from "../../interfaces/ICommentResponse";

registerLocale("pt-BR", ptBR);

const QuestionList = () => {
  const [items, setItems] = useState<IQuestionWithComments[]>([]); // Perguntas sem comentários
  const [questionsAfterCommentsAdmin, setQuestionsAfterCommentsAdmin] = useState<IQuestionWithComments[]>([]); // Comentários depois do admin
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<IQuestionWithComments | undefined>(undefined);

  // Método para carregar a lista de perguntas
  const loadList = () => {
    setLoading(true);
    lessonService
      .getCommentsAfterLastAdmin() // Passe o id correto aqui
      .then((response) => {
        //console.log('Resposta da API:', response);
        setItems(response.questionsWithoutComments || []); // Define perguntas sem comentários
        setQuestionsAfterCommentsAdmin(response.questionsAfterCommentsAdmin || []); // Define comentários depois do admin
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Erro ao carregar dados:', error);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (item: IQuestionWithComments) => {
    setSelectedItem(item);
    setShow(true);
  };

  const handleCommentUpdate = () => {
    loadList(); // Atualiza as listas
    handleClose(); // Fecha o modal
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Perguntas dos usuários</h3>
            </div>
            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <br />

          {/* Primeira Tabela: Perguntas sem comentários */}
          <h2 className="title">Não comentado pelo Admin</h2>
          <Table className="tabela" striped bordered hover>
            <thead>
              <tr className="tr">
                <th style={{ width: '10%' }}>Usuário</th>
                <th>Pergunta</th>
                <th className="data-hora" style={{ width: '10%' }}>Data/hora</th>
                <th className="acoes" style={{ width: '10%' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={4}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((item: IQuestionWithComments) => (
                <tr key={item.id}>
                  <td><span>{item.user?.name}</span></td>
                  <td><span>{item.text}</span></td>
                  <td><span>{moment(item.created_at).format("DD/MM/yyyy HH:mm")}</span></td>
                  <td>
                    <div className="buttonLesson">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleShow(item)}
                      >
                        Responder
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={4}>
                    <p>Nenhuma pergunta pendente para responder.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Segunda Tabela: Comentários depois do admin */}
          <h2 className="title">Depois de comentado pelo Admin</h2>
          <Table className="tabela" striped bordered hover>
            <thead>
              <tr className="tr">
                <th style={{ width: '10%' }}>Usuário</th>
                <th>Pergunta</th>
                <th>Comentário</th>
                <th className="data-hora" style={{ width: '10%' }}>Data/hora</th>
                <th className="acoes" style={{ width: '10%' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={5}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {questionsAfterCommentsAdmin.map((item: IQuestionWithComments) => (
                <tr key={item.id}>
                  <td><span>{item.user?.name}</span></td>
                  <td><span>{item.text}</span></td>
                  <td>
                    <span>
                      {item.comments.length > 0 && (
                        <>
                          <strong>{item.comments[0].user?.name}:</strong> {item.comments[0].text}
                        </>
                      )}
                    </span>
                  </td>
                  <td><span>{moment(item.created_at).format("DD/MM/yyyy HH:mm")}</span></td>
                  <td>
                    <div className="buttonLesson">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleShow(item)}
                      >
                        Responder
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {questionsAfterCommentsAdmin.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={5}>
                    <p>Nenhuma pergunta pendente para responder.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <CommentForm 
          question={selectedItem} 
          callback={handleCommentUpdate} 
          closecomment={handleClose} 
          visible={show}
        />
      </div>
    </>
  );
};

export default QuestionList;
