import { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { LinearProgress } from "@mui/material";
import { TableFooter } from "@mui/material";

import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import userService from "../../services/UserService";
import IUser from "../../interfaces/IUser";
import { UserType } from "../../enums/UserType";
import lessonService from "../../services/LessonService";
import ILesson from "../../interfaces/ILesson";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLessons from "../Lessons";

registerLocale("pt-BR", ptBR);

const LessonsList = (props: any) => {
  const [items, setItems] = useState<ILesson[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  // const [userId, setUserId] = useState<number>();
  const [name, setName] = useState<string>();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<number>(location.state.idCourse);

  const goToCourses = () => {
    navigate("/list-courses");
  };

  const navigate = useNavigate();

  const loadList = () => {
    setLoading(true);
    console.log("teste", courseId);
    if (courseId) {
      lessonService
        .getAll(courseId)
        .then((response) => {
          let lessons: ILesson[] = response.data;
          setItems(lessons);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleDelete = (id: number) => {
    lessonService
      .delete(id)
      .then((response) => {
        loadList();
      })
      .catch((error) => {
        alert("Não foi possível excluir aula");
      });
  };

  useEffect(() => {
    loadList();
  }, [courseId]);

  const goToQuestions = (idLesson: number) => {
    navigate("/list-quiz-questions", { state: { idLesson, courseId } });
  };

  const goToLessonsRelated = (idLesson: number) => {
    navigate("/list-lessons-related", { state: { idLesson, courseId } });
  };




  const handleClose = () => {

    setShow(false);
  };
  const handleShow = () => {
    setShow(true);

  };

  useEffect(() => { if (show === false) { loadList() } }, [show])


  return (
    <>

      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">

            <div className="title">
              <h3>Lista de aulas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <div className="voltar" >
              <button
                style={{ width: '100px' }}
                className="btn btn-primary"
                onClick={goToCourses}
              >
                Voltar
              </button>
              <Button className="btn btn-primary m-2" onClick={handleShow} >
                Cadastrar Aulas
              </Button>
            </div>
          </div>

          <br />
          <Table
            className="tabela"
            striped
            bordered
            hover
          >
            <thead>
              <tr className="tr">

                <th style={{ textAlign: "center", fontSize: 25 }}>Aulas</th>
                <th style={{ textAlign: "center", fontSize: 25 }}>
                  Descrição
                </th>

                <th style={{ textAlign: "center", fontSize: 25, width: 500 }}>
                  Ação
                </th>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((item: ILesson) => (
                <tr key={item.id}>


                  <td>
                    <span>{item.name}</span>
                  </td>

                  <td>
                    <span>{item.description}</span>
                  </td>

                  <td style={{ textAlign: "center", alignContent: "center" }}>
                    <button
                      className="btn btn-warning"
                      onClick={() => goToLessonsRelated(item.id)}
                    >
                      Aulas Relacionadas
                    </button>

                    <button
                      className="btn btn-primary"
                      style={{ marginRight: 10, marginLeft: 10 }}
                      onClick={() => goToQuestions(item.id)}
                    >
                      Perguntas
                    </button>

                    <button className="btn btn-danger"
                      style={{ marginRight: 10 }}
                      onClick={(e) => { handleDelete(item.id) }}>
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}

              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={2}>
                    <p>Nenhum aula encontrada.</p>
                  </td>
                </tr>
              )}
            </tbody>

            <TableFooter>
              {isLoading && (
                <tr className="tr">
                  <th colSpan={3}>
                    <LinearProgress variant="indeterminate" />
                  </th>
                </tr>
              )}
            </TableFooter>
          </Table>
        </div>
      </div>

      <ModalLessons {...{ show, handleClose, courseId }} />
    </>
  );
};

export default LessonsList;
