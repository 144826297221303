import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import { Checkbox } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import ICourse from "../../interfaces/ICourse";
import ICarrerCourseCreate from "../../interfaces/ICareerCourseCreate";
import courseService from "../../services/CourseService";
import careerPathService from "../../services/CareerPathService";

registerLocale("pt-BR", ptBR);

const ModalCareerLessons = ({ show, handleClose, idCareer }: { show: boolean; handleClose: () => void, idCareer: number }) => {
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [createArray, setcreateArray] = useState<{ course: ICourse, order: string, active: boolean }[]>([]);
  const [courses, setCourses] = useState<ICourse[]>();

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    // Validação: Verifica se há algum campo `order` vazio para itens ativos
    const hasEmptyOrder = createArray.some((item) => item.active && item.order === "");

    if (hasEmptyOrder) {
      setError(true);
      setMessage("Todos os campos de ordem devem ser preenchidos.");
      return;
    }

    // Se a validação passar, continue com o processo de envio
    const data: ICarrerCourseCreate = {
      createObj: createArray.filter(item => item.active === true).map((item) => {
        return { course: item.course, order: Number(item.order) }
      }),
      idCareerPath: idCareer,
    };
         
    careerPathService
      .createCareerCourses(data)
      .then((response) => {
        setcreateArray([]);
        handleClose();
      })
      .catch((error:any) => {   
        setError(true);      
        alert("Erro ao atualizar o curso: " + error.response.data.errorMessage);
      });
  };

  const loadList = () => {
    courseService
      .getAll()
      .then((response) => {
        let courses: ICourse[] = response.data;
        setcreateArray(courses.map((course) => {
          return {
            course: course,
            order: "",
            active: false
          }
        }))
        setCourses(courses);
      })
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Cadastrar Curso</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {createArray && createArray.map((item) => (
            <Form.Group className="mb-1" key={item.course.id}>
              <Checkbox checked={item.active} onChange={() => {
                let newValue = createArray.map((arrItem) => {
                  if (arrItem.course.name === item.course.name) {
                    arrItem.active = !item.active;
                  }
                  return arrItem;
                })
                setcreateArray(newValue)
              }} />
              <Form.Label htmlFor="inputNameCustomer">{`${item.course.name}`}</Form.Label>
              <Form.Control
                disabled={!item.active}
                value={item.order}
                onChange={(e: any) => {
                  let newValue = createArray.map((arrItem) => {
                    if (arrItem.course.name === item.course.name) {
                      arrItem.order = e.target.value;
                    }
                    return arrItem;
                  })
                  setcreateArray(newValue)
                }}
                type="number" // Assegura que o campo aceite apenas números
                isInvalid={error && item.active && item.order === ""}
              />
              <Form.Control.Feedback type="invalid">
                {item.active && item.order === "" ? "Este campo é obrigatório." : ""}
              </Form.Control.Feedback>
            </Form.Group>
          ))}
        </Form>
        {error && message && (
          <Alert variant="danger" onClose={() => setError(false)} dismissible>
            {message}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => handleClick(e)}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCareerLessons;
