// DevRegionList.tsx

import React, { useEffect, useState } from "react";
import { Table, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../shared/global.scss";
import devsRegionService from "../../services/DevsRegionService";

interface RegionData {
  region: string;
  totalDevs: number;
}

const DevRegionList = () => {
  const [data, setData] = useState<RegionData[]>([]);
  const [totalDevelopers, setTotalDevelopers] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Função para buscar dados da API usando o serviço
    const fetchData = async () => {
      try {
        const result = await devsRegionService.getCountByRegion();
        setData(result.data);

        // Encontrar o total geral de desenvolvedores
        const total = result.data.find((region: RegionData) => region.region === 'Total');
        setTotalDevelopers(total ? total.totalDevs : 0);
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        setError("Erro ao obter dados do servidor.");
      } finally {
        setIsLoading(false);
      }
    };

    // Chama a função para buscar dados ao carregar a página
    fetchData();
  }, []);

  return (
    <div className="" style={{marginLeft:'8%', marginTop:'50px'}}>
      <h1 style={{textAlign: 'center'}}>Desenvolvedores por Região</h1>

      {error && <Alert variant="danger">{error}</Alert>}

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <Table className="tabela" striped bordered hover>
          <thead>
            <tr>
              <th style={{width:'50px'}}>Região</th>
              <th style={{width:'10px'}}>Total de Desenvolvedores</th>
            </tr>
          </thead>
          <tbody>
            {data.map((regionData, index) => (
              <tr key={index}>
                <td>{regionData.region}</td>
                <td>{regionData.totalDevs}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!isLoading && (
        <div id="totalDevelopers" className="text-center mt-3">
          <h2><strong>Total Geral de Desenvolvedores no Talent Formação de Dev's:</strong> {totalDevelopers} alunos.</h2>
        </div>
      )}
    </div>
  );
};

export default DevRegionList;
