import api from "./api";


class CareerPathService {
  async getAll(): Promise<any> {
    return api.get("career-path/all")
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
  async create(data: any): Promise<any>{
    return api({
        url: "/career-path/create",
        method: "POST",
        timeout: 5000,
        data: data,
        
    }).then((result) => {
        return Promise.resolve(result)
    }).catch((error) => {
        return Promise.reject(error)
    })
}

async createCareerCourses(data: any): Promise<any>{
  console.log(data)
  return api({
      url: "/career-course/create-career-courses",
      method: "POST",
      timeout: 5000,
      data: data,
      
  }).then((result) => {
      return Promise.resolve(result)
  }).catch((error) => {
      return Promise.reject(error)
  })
}

async getAllCareerCourses(id:number): Promise<any> {
  return api.get("career-course/career-course/"+id)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  // return Promise.resolve ({status: true})
}


async delete(idCourse: number): Promise<any>{   
  return api
  .delete(`career-path/${idCourse}`)
  .then((result) => {
      return Promise.resolve(result)
  }).catch((error) => {
      return Promise.reject(error)
  })
}



async updateCourse(data: any, id: number): Promise<any> {
  try {
    const result = await api({
      url: `/career-course/update/${id}`,
      method: "PUT",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    });
    return result;
  } catch (error) {
    console.error("Erro ao atualizar o curso:", error);
    throw error;
  }
}



}

const careerPathService = new CareerPathService();
export default careerPathService;
