import api from "./api";

class FileService {
  async upload(body: any): Promise<any> {
    return api
      .post("/files/create", body, {
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: (data, headers) => {
          return body;
        },
      })

      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    // return Promise.resolve ({status: true})
  }
}

const fileService = new FileService();
export default fileService;
