import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Alert,
  FormCheck,
  InputGroup,
} from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { PatternFormat } from "react-number-format";
import IQuizQuestions from "../../interfaces/IQuizQuestions";
import { QuizQuestionsAlternativeCorrectType } from "../../enums/QuizQuestionsAlternativeType";
import { QuizQuestionsChoicesType } from "../../enums/QuizQuestionsChoicesType";
import { QuizQuestionsLevelType } from "../../enums/QuizQuestionsLevelType";
import quizQuestionService from "../../services/QuizQuestionsService";
import { is } from "date-fns/locale";
import { useLocation, useNavigate } from "react-router-dom";
import ICourse from "../../interfaces/ICourse";
import ILesson from "../../interfaces/ILesson";
import tinymce from "tinymce";
import fileService from "../../services/FileService";
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'

registerLocale("pt-BR", ptBR);

const QuizQuestionsList = () => {
  const [items, setItems] = useState<IQuizQuestions[]>([]);
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [questionId, setQuestionId] = useState<number>();
  const [question, setQuestion] = useState<string>();
  const [alternative1, setAlternative1] = useState<string | undefined>();
  const [alternativeCorrect1, setAlternativeCorrect1] = useState<string>("N");
  const [alternative2, setAlternative2] = useState<string | undefined>();
  const [alternativeCorrect2, setAlternativeCorrect2] = useState<string>("N");
  const [alternative3, setAlternative3] = useState<string | undefined>();
  const [alternativeCorrect3, setAlternativeCorrect3] = useState<string>("N");
  const [alternative4, setAlternative4] = useState<string | undefined>();
  const [alternativeCorrect4, setAlternativeCorrect4] = useState<string>("N");
  const [level, setLevel] = useState<string | undefined>("Fácil");
  const [choice, setChoice] = useState<string | undefined>("UNICA_ESCOLHA");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  const [lessonId, setLessonId] = useState<number>(location.state?.idLesson);
  const [courseId, setCourseId] = useState<number>(location.state?.courseId);
  const [file, setFile] = useState<File>();
  const navigate = useNavigate();

  const goToLesson = () => {
    navigate("/list-lessons", { state: { idCourse: courseId } });
  };

  const editorRef = useRef<any>(null);
  const [message, setMessage] = useState("");
  const [countChars, setCountChars] = useState(0);
  const MAX_CHARS = 900;

  const getCountChars = (): number => {
    const rawContent = editorRef.current
      .getContent({ format: "raw" })
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/&nbsp;/g, "")
      .trim();
    return rawContent.length;
  };

  const upload = () => {
    if (!file) return;
    const body = new FormData();
    body.append("file", file);

    fileService.upload(body);
  };

  const saveText = () => {
    if (editorRef.current) {
      setMessage(editorRef.current.getContent({ format: "raw" }));
    }
  };

  const loadList = () => {
    setLoading(true);
    let promise: Promise<any>;
    if (lessonId) {
      promise = quizQuestionService.getAllByLesson(lessonId);
    } else {
      promise = quizQuestionService.getAll();
    }
    promise
      .then((response) => {
        let questions: IQuizQuestions[] = response.data;
        setItems(questions);
        setLoading(false);
      })
      .catch((_error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  const handleShowUpdate = (question: IQuizQuestions) => {
    setQuestionId(question.id);
    setQuestion(question.question);
    setAlternative1(question.alternative1);
    setAlternative2(question.alternative2);
    setAlternative3(question.alternative3);
    setAlternative4(question.alternative4);
    setAlternativeCorrect1(question.alternativeCorrect1);
    setAlternativeCorrect2(question.alternativeCorrect2);
    setAlternativeCorrect3(question.alternativeCorrect3);
    setAlternativeCorrect4(question.alternativeCorrect4);
    setLevel(question.difficultyLevel);
    setChoice(question.type);
    handleShow();
  };

  const setCorrectAlternativeRadioButton = (alternativeNumber: number) => {
    let finalValue = "S";
    setAlternativeCorrect1("N");
    setAlternativeCorrect2("N");
    setAlternativeCorrect3("N");
    setAlternativeCorrect4("N");
    if (alternativeNumber === 1) {
      setAlternativeCorrect1(finalValue);
    } else if (alternativeNumber === 2) {
      setAlternativeCorrect2(finalValue);
    } else if (alternativeNumber === 3) {
      setAlternativeCorrect3(finalValue);
    } else if (alternativeNumber === 4) {
      setAlternativeCorrect4(finalValue);
    }
  };

  const setCorrectAlternativeCheckBox = (
    isChecked: boolean,
    alternativeNumber: number
  ) => {
    let finalValue = isChecked === true ? "S" : "N";
    if (alternativeNumber === 1) {
      setAlternativeCorrect1(finalValue);
    } else if (alternativeNumber === 2) {
      setAlternativeCorrect2(finalValue);
    } else if (alternativeNumber === 3) {
      setAlternativeCorrect3(finalValue);
    } else if (alternativeNumber === 4) {
      setAlternativeCorrect4(finalValue);
    }
  };

  const handleClick = (e: React.FormEvent)=> {
    e.preventDefault();
    console.log(!file || !question || !alternative1 || !alternative2 || !alternative3 || !alternative4 || !level || !choice)
    const body = new FormData();
    let data: any
    if(file){
    if(!file || !question || !alternative1 || !alternative2 || !alternative3 || !alternative4 || !level || !choice) return;    
    body.append("file", file);
    body.append("question", question);
    body.append("alternative1", alternative1);
    body.append("alternativeCorrect1", alternativeCorrect1);
    body.append("alternative2", alternative2);
    body.append("alternativeCorrect2", alternativeCorrect2);
    body.append("alternative3", alternative3);
    body.append("alternativeCorrect3", alternativeCorrect3);
    body.append("alternative4", alternative4);
    body.append("alternativeCorrect4", alternativeCorrect4);
    body.append("difficultyLevel", level);
    body.append("type", choice);
    if(lessonId){
      body.append("lessonId", String(lessonId));
    }
  }else{
    data={
      question:question, 
      alternative1:alternative1,
      alternativeCorrect1:alternativeCorrect1,
      alternative2:alternative2,
      alternativeCorrect2:alternativeCorrect2,
      alternative3:alternative3,
      alternativeCorrect3:alternativeCorrect3,
      alternative4:alternative4,
      alternativeCorrect4:alternativeCorrect4,
      difficultyLevel:level,
      type:choice,
      lessonId:lessonId,
    }
  }
           
    const reqBody = !file?data:body;
    
    if (questionId) {
      quizQuestionService
        .update(reqBody, questionId)
        .then((response) => {
          loadList();
          handleClose();
          setQuestionId(undefined);
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      quizQuestionService
        .create(reqBody)
        .then((response) => {
          setFile(undefined)
          loadList();
          handleClose();
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const handleDelete = async (id: number) => {
    quizQuestionService
      .delete(id)
      .then((response) => {
        loadList();
        handleClose();
      })
      .catch((error) => {
        alert("Não foi possível excluir a pergunta");
      });
  };

  const handleClose = () => {
    setQuestionId(undefined);
    setQuestion(undefined);
    setAlternative1(undefined);
    setAlternative2(undefined);
    setAlternative3(undefined);
    setAlternative4(undefined);
    setAlternativeCorrect1("");
    setAlternativeCorrect2("");
    setAlternativeCorrect3("");
    setAlternativeCorrect4("");
    setLevel("Fácil");
    setChoice("UNICA_ESCOLHA");
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setError(false);
  };

  const handleCloseQuestions = () => {
    setShowQuestions(false);
  };

  return (
    <>
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Perguntas das aulas</h3>
            </div>      
        
            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar pergunta
            </Button>
          </div>
          <br />
          <div className="div-table" >
            <ScrollContainer>
            <Table striped bordered hover>
              <thead>
                <tr className="tr">
                  <th>Pergunta</th>
                  <th>Alternativa 1</th>
                  <th>Alternativa 1 Correta?</th>
                  <th>Alternativa 2</th>
                  <th>Alternativa 2 Correta?</th>
                  <th>Alternativa 3</th>
                  <th >Alternativa 3 Correta?</th>
                  <th>Alternativa 4</th>
                  <th>Alternativa 4 Correta?</th>
                  <th>Nível</th>
                  <th>Tipo</th>
                  <th >Ações</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                )}
                {items.map((question: IQuizQuestions) => (
                  <tr key={question.id}>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{ __html: question.question }}
                      />
                    </td>
                    <td>
                      <span>{question.alternative1}</span>
                    </td>
                    <td>
                      <span>{question.alternativeCorrect1}</span>
                    </td>
                    <td>
                      <span>{question.alternative2}</span>
                    </td>
                    <td>
                      <span>{question.alternativeCorrect2}</span>
                    </td>
                    <td >
                      <span>{question.alternative3}</span>
                    </td>
                    <td>
                      <span>{question.alternativeCorrect3}</span>
                    </td>
                    <td>
                      <span>{question.alternative4}</span>
                    </td>
                    <td>
                      <span>{question.alternativeCorrect4}</span>
                    </td>
                    <td>
                      <span>{question.difficultyLevel}</span>
                    </td>
                    <td>
                      <span>{question.type}</span>
                    </td>
                    <td
                      style={{ display:'flex' }}
                    >
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: 10, marginLeft: 10 }}
                        onClick={(_e) => handleShowUpdate(question)}
                      >
                        Atualizar
                      </button>
                      <button
                        className="btn btn-danger"
                        style={{ marginRight: 10, marginLeft: 10 }}
                        onClick={(_e) => handleDelete(question.id)}
                      >
                        Deletar
                      </button>
                    </td>
                  </tr>
                ))}
                {/* {items.length === 0 && !isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <p>Nenhuma escola encontrada.</p>
                    </td>
                  </tr>
                )} */}
              </tbody>
            </Table>
            </ScrollContainer>
            <div className="voltar">
             { lessonId && <button
                style={{ width: 200, marginLeft: 1900 }}
                className="btn btn-primary"
                onClick={goToLesson}
              >
                voltar
              </button>}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Pergunta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer"><strong>Nível</strong></Form.Label>
              <Form.Select onChange={(e) => setLevel(e.target.value)}>
                {level === "Facil" && (
                  <>
                    <option selected value={QuizQuestionsLevelType.FACIL}>
                      Fácil
                    </option>
                    <option value={QuizQuestionsLevelType.MEDIO}>Médio</option>
                    <option value={QuizQuestionsLevelType.DIFICIL}>
                      Difícil
                    </option>
                  </>
                )}

                {level === "Medio" && (
                  <>
                    <option value={QuizQuestionsLevelType.FACIL}>Fácil</option>
                    <option selected value={QuizQuestionsLevelType.MEDIO}>
                      Médio
                    </option>
                    <option value={QuizQuestionsLevelType.DIFICIL}>
                      Difícil
                    </option>
                  </>
                )}
                {level === "Dificil" && (
                  <>
                    <option value={QuizQuestionsLevelType.FACIL}>Fácil</option>
                    <option value={QuizQuestionsLevelType.MEDIO}>Médio</option>
                    <option selected value={QuizQuestionsLevelType.DIFICIL}>
                      Difícil
                    </option>
                  </>
                )}
                {level !== "Facil" &&
                  level !== "Medio" &&
                  level !== "Dificil" && (
                    <>
                      <option value={QuizQuestionsLevelType.FACIL}>
                        Fácil
                      </option>
                      <option value={QuizQuestionsLevelType.MEDIO}>
                        Médio
                      </option>
                      <option value={QuizQuestionsLevelType.DIFICIL}>
                        Difícil
                      </option>
                    </>
                  )}
              </Form.Select>
              <br></br>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputNameCustomer">
                 <strong>Múltipla ou Única Escolha</strong>
                </Form.Label>
                <Form.Select
                  onChange={(e) => {
                    setChoice(e.target.value);
                    setAlternativeCorrect1("N");
                    setAlternativeCorrect2("N");
                    setAlternativeCorrect3("N");
                    setAlternativeCorrect4("N");
                  }}
                >
                  {choice === "UNICA_ESCOLHA" && (
                    <>
                      <option
                        selected
                        value={QuizQuestionsChoicesType.UNICA_ESCOLHA}
                      >
                        Única Escolha
                      </option>
                      <option value={QuizQuestionsChoicesType.MULTIPLA_ESCOLHA}>
                        Múltipla Escolha
                      </option>
                    </>
                  )}
                  {choice === "MULTIPLA_ESCOLHA" && (
                    <>
                      <option
                        selected
                        value={QuizQuestionsChoicesType.MULTIPLA_ESCOLHA}
                      >
                        Múltipla Escolha
                      </option>
                      <option value={QuizQuestionsChoicesType.UNICA_ESCOLHA}>
                        Única escolha
                      </option>
                    </>
                  )}
                  {choice !== "MULTIPLA_ESCOLHA" &&
                    choice !== "UNICA_ESCOLHA" && (
                      <>
                        <option value={QuizQuestionsChoicesType.UNICA_ESCOLHA}>
                        Única Escolha
                        </option>
                        <option
                          value={QuizQuestionsChoicesType.MULTIPLA_ESCOLHA}
                        >
                          Múltipla Escolha
                        </option>
                      </>
                    )}
                </Form.Select>
              </Form.Group>
            </Form.Group>
            <br></br>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer"><strong>Upload de arquivo</strong></Form.Label>
              <Form.Control type="file" onChange={(e:React.ChangeEvent<HTMLInputElement>) => {if(e.target.files) {setFile(e.target.files[0])}}}/>
              </Form.Group>
            <Form.Group className="mb-3">
            <br></br>
              <Form.Label htmlFor="inputNameCustomer"><strong>Pergunta</strong></Form.Label>

              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey={"2wlq25r4r0lkyin5jznlkc4wgibjjx2q44t9tuvd0fowobei"}
                onBlur={saveText}
                init={{
                  width: "100%",
                  height: 300,
                  plugins: [
                    "autolink link charmap preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "paste wordcount",
                    "media",
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                  ],
                  toolbar_mode: "sliding",
                  automatic_uploads: true,
                  image_title: true,
                  file_picker_types: "image",
                  font_family_formats:
                    "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                  font_size_formats:
                    "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                  menubar: "file edit view insert format tools table tc help",
                  toolbar:
                    "undo redo | link image | code |fontfamily fontsize | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks |    alignleft aligncenter alignright alignjustify | image |outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment |link image media table mergetags |footnotes | mergetags | emoticons charmap |'undo redo | blocks | bold italic forecolor | alignleft aligncenter |alignright alignjustify | bullist numlist outdent indent | removeformat | help',",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
                  image_upload_url: "uploadImage",
                  images_file_types: "jpg,svg,webp",

                  setup: function (ed) {
                    var allowedKeys = [8, 37, 38, 39, 40, 46, 16, 17, 18]; // backspace, delete and cursor keys, shift, ctrl, alt
                    ed.on("keydown", function (e) {
                      if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
                      if (getCountChars() + 1 > MAX_CHARS) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      return true;
                    });
                    ed.on("keyup", function (e) {
                      setCountChars(getCountChars());
                    });
                  },
                  init_instance_callback: function () {
                    // initialize counter div
                    setCountChars(getCountChars());
                  },
                }}
                value={question}
                // onChange={(e) => setQuestion(e.target.value)}
                onEditorChange={(value, editor) => {
                  setQuestion(value);
                  // console.log(typeof value)
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ marginLeft: 30 }}
                htmlFor="inputNameCustomer"
              >
                <strong>Alternativa 1</strong>
              </Form.Label>
              <div className="container-form-control">
                {choice === "MULTIPLA_ESCOLHA" ? (
                  <FormCheck
                    style={{ width: 30 }}
                    type="checkbox"
                    checked={alternativeCorrect1 === "S"}
                    value={alternativeCorrect1}
                    onChange={(e) =>
                      setCorrectAlternativeCheckBox(e.target.checked, 1)
                    }
                  />
                ) : (
                  <FormCheck
                    style={{ width: 30 }}
                    name="radio"
                    type="radio"
                    checked={alternativeCorrect1 === "S"}
                    value={alternativeCorrect1}
                    onChange={(e) => setCorrectAlternativeRadioButton(1)}
                  />
                )}
                <Form.Control
                  as="textarea"
                  rows={1}
                  type="text"
                  value={alternative1}
                  onChange={(e) => setAlternative1(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ marginLeft: 30 }}
                htmlFor="inputNameCustomer"
              >
               <strong> Alternativa 2</strong>
              </Form.Label>
              <div className="container-form-control">
                {choice === "MULTIPLA_ESCOLHA" ? (
                  <FormCheck
                    style={{ width: 30 }}
                    type="checkbox"
                    checked={alternativeCorrect2 === "S"}
                    value={alternativeCorrect2}
                    onChange={(e) =>
                      setCorrectAlternativeCheckBox(e.target.checked, 2)
                    }
                  />
                ) : (
                  <FormCheck
                    style={{ width: 30 }}
                    type="radio"
                    name="radio"
                    checked={alternativeCorrect2 === "S"}
                    value={alternativeCorrect2}
                    onChange={(e) => setCorrectAlternativeRadioButton(2)}
                  />
                )}
                <Form.Control
                  as="textarea"
                  rows={1}
                  type="text"
                  value={alternative2}
                  onChange={(e) => setAlternative2(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ marginLeft: 30 }}
                htmlFor="inputNameCustomer"
              >
                <strong>Alternativa 3</strong>
              </Form.Label>
              <div className="container-form-control">
                {choice === "MULTIPLA_ESCOLHA" ? (
                  <FormCheck
                    style={{ width: 30 }}
                    type="checkbox"
                    checked={alternativeCorrect3 === "S"}
                    value={alternativeCorrect3}
                    onChange={(e) =>
                      setCorrectAlternativeCheckBox(e.target.checked, 3)
                    }
                  />
                ) : (
                  <FormCheck
                    style={{ width: 30 }}
                    type="radio"
                    name="radio"
                    checked={alternativeCorrect3 === "S"}
                    value={alternativeCorrect3}
                    onChange={(e) => setCorrectAlternativeRadioButton(3)}
                  />
                )}
                <Form.Control
                  as="textarea"
                  rows={1}
                  type="text"
                  value={alternative3}
                  onChange={(e) => setAlternative3(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ marginLeft: 30 }}
                htmlFor="inputNameCustomer"
              >
                <strong>Alternativa 4</strong>
              </Form.Label>
              <div className="container-form-control">
                {choice === "MULTIPLA_ESCOLHA" ? (
                  <FormCheck
                    style={{ width: 30 }}
                    type="checkbox"
                    checked={alternativeCorrect4 === "S"}
                    value={alternativeCorrect4}
                    onChange={(e) =>
                      setCorrectAlternativeCheckBox(e.target.checked, 4)
                    }
                  />
                ) : (
                  <FormCheck
                    style={{ width: 30 }}
                    name="radio"
                    type="radio"
                    checked={alternativeCorrect4 === "S"}
                    value={alternativeCorrect4}
                    onChange={(e) => setCorrectAlternativeRadioButton(4)}
                  />
                )}
                <Form.Control
                  as="textarea"
                  rows={1}
                  type="text"
                  value={alternative4}
                  onChange={(e) => setAlternative4(e.target.value)}
                />
              </div>
            </Form.Group>
          </Form>
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Pergunta já cadastrada
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setQuestionId(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showQuestions} onHide={handleCloseQuestions}>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuestions}>
            Cancelar
          </Button>
          {
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
              
            >
              Salvar
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuizQuestionsList;
