import api from "./api";

interface LessonsSkillsCreateDto {
    id_lesson: number | undefined;
  }

class LessonSkillsService {
   

    async create(data: any): Promise<any>{
      return api({
          url: "/lesson-skills/create",
          method: "POST",
          timeout: 5000,
          data: data,
          
      }).then((result) => {
          return Promise.resolve(result)
      }).catch((error) => {
          return Promise.reject(error)
      })
    }
 
    async getSkills(): Promise<any>{
        return api({
            url: "/skill",
            method: "GET",
            timeout: 5000
            
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
      }


      async getSubSkills(id: number): Promise<any> {
        return api({
          url: `/sub-skill/skill/${id}` ,
          method: "GET",
          timeout: 5000,
        }).then((result) => {
          return Promise.resolve(result)
        }).catch((error) => {
          return Promise.reject(error)
        })
      }

      async getSubSkillsByLesson(lessonId: number): Promise<any> {
        return api({
          url: `/lessons/${lessonId}/sub-skills`,
          method: "GET",
          timeout: 5000,
        }).then((result) => {
          return Promise.resolve(result)
        }).catch((error) => {
          return Promise.reject(error)
        })
      }
    
      async addSubSkillToLesson(lessonId: number, subSkillId: number): Promise<any> {
        return api({
          url: `/lessons-sub-skills/sub-skill/${lessonId}`,
          method: "POST",
          timeout: 5000,
          data: { subSkillId },
        })
        .then((result) => {
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
      }


      async getSkillById(id: number): Promise<any> {
        return api({
          url: `/skill/${id}`, // Endpoint para buscar uma skill específica pelo id
          method: "GET",
          timeout: 5000,
        })
          .then((result) => {
            return Promise.resolve(result);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }

      async createSubSkills(data: any): Promise<any>{
        return api({
            url: "/sub-skill/create",
            method: "POST",
            timeout: 5000,
            data: data,
            
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
      }

      async getSubSkillsBySkill(id: number): Promise<any>{
        return api({
            url: "/sub-skill/skills-sub-skills/" + id,
            method: "GET",
            timeout: 5000
            
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
      }

      async updateSubSkill(data: any, id: number): Promise<any>{
        return api({
            url: `/sub-skill/update/` + id,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async delete(id: number): Promise<any> {
        return api
          .delete(`/sub-skill/` + id)
          .then((result) => {
            return Promise.resolve(result);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
}

const lessonSkillsService = new LessonSkillsService();
export default lessonSkillsService;
