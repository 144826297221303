import api from "./api";

class LoginService {
  async login(data: any): Promise<any> {
    return api({
      url: "/users/login",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}

const loginService = new LoginService();
export default loginService;
