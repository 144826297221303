import api from "./api";

interface SkillsCreateDto {
    id_skill: number | undefined;
  }

class SkillService {
   

    async create(data: any): Promise<any>{
      return api({
          url: "/skill/create",
          method: "POST",
          timeout: 5000,
          data: data,
          
      }).then((result) => {
          return Promise.resolve(result)
      }).catch((error) => {
          return Promise.reject(error)
      })
    }


    async updateSkill(data: any, id: number): Promise<any>{
        return api({
            url: `/skill/update/` + id,
            method: "PUT",
            timeout: 5000,
            data: data,
            headers: {Accept: 'application/json'}
        }).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }


    async delete(id: number): Promise<any> {
        return api
          .delete(`skill/` + id)
          .then((result) => {
            return Promise.resolve(result);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
 
    
}

const skillService = new SkillService();
export default skillService;
