import api from "./api";

class DevsRegionService {
  async getCountByRegion(): Promise<any> {
    try {
      // Faz a requisição para o endpoint do NestJS
      const response = await api.get("students/count-by-region");

      // Verifica se a resposta contém status true
      if (response.status) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject({
          message: "Erro ao obter dados.",
        });
      }
    } catch (error) {
      // Tratamento de erro, incluindo erros de conexão ou resposta inválida
    }
  }
}

const devsRegionService = new DevsRegionService();
export default devsRegionService;
