import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Alert,
  Stack,
  Badge,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "../../shared/global.scss";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-indiana-drag-scroll/dist/style.css";
import lessonSkillsService from "../../services/LessonSkillsService";
import LessonsSkillsCreateDto from "../../interfaces/ILessonSkillsCreate";
import ISkill from "../../interfaces/ISkill";
import ISelectedSkill from "../../interfaces/ISelectedSkill";
import ISubSkills from "../../interfaces/ISubSkills";

registerLocale("pt-BR", ptBR);

const ModalLessons = ({
  show,
  handleClose,
  courseId,
}: {
  show: boolean;
  handleClose: () => void;
  courseId: number;
}) => {
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [linkYoutube, setLinkYoutube] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [order, setOrder] = useState<number>(0);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [showSubSkillModal, setShowSubSkillModal] = useState<boolean>(false);
  const [subSkillDescription, setSubSkillDescription] = useState<string>("");
  const [selectedSkillId, setSelectedSkillId] = useState<number | null>(null);
  const [subSkills, setSubSkills] = useState<ISubSkills[]>([]);

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();

    let subSkillsId: number[] = [];

    const listSkills = selectedSkills.map((skill) => ({
      id: skill.id,
      value: Number(skill.value),
      subSkills: skill.subSkills,
    }));

    if (subSkills) {
      subSkills.map((dataSubSkill) => {
        if (dataSubSkill.selected) {
          subSkillsId.push(dataSubSkill.id);
        }
      });
    }

    const data: LessonsSkillsCreateDto = {
      name,
      description,
      link_youtube: linkYoutube,
      duration,
      order,
      courseId,
      skills: listSkills,
      subSkillId: subSkillsId,
    };

    console.log(data);

    lessonSkillsService
      .create(data)
      .then(() => {
        resetForm();
        handleClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    lessonSkillsService
      .getSkills()
      .then((response) => {
        setSkills(response.data);
      })
      .catch(() => {
        alert("Não foi possível listar as skills");
      });
  }, []);

  const handleSubSkillsClick = (skillId: number) => {
    setSelectedSkillId(skillId);
    lessonSkillsService
      .getSubSkills(skillId)
      .then((response) => {
        let data: ISubSkills[] = response.data.map((subSkill: any) => ({
          ...subSkill,
          idSkill: skillId,
        }));
        setSubSkills(data);
        setShowSubSkillModal(true);
      })
      .catch(() => {
        alert("Não foi possível listar as subskills");
      });
  };

  const handleSaveSubSkill = () => {
    if (selectedSkillId === null) return;

    const selectedSubSkills = subSkills.filter((subSkill) => subSkill.selected);

    const updatedSkills = selectedSkills.map((skill) => {
      if (skill.id === selectedSkillId) {
        return {
          ...skill,
          subSkills: selectedSubSkills.map((subSkill) => subSkill.id),
        };
      }
      return skill;
    });

    setSelectedSkills(updatedSkills);
    setShowSubSkillModal(false);
  };

  const handleSubSkillSelection = (subSkillId: number) => {
    setSubSkills((prevSubSkills) =>
      prevSubSkills.map((subSkill) =>
        subSkill.id === subSkillId
          ? { ...subSkill, selected: !subSkill.selected }
          : subSkill
      )
    );
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setLinkYoutube("");
    setDuration(0);
    setOrder(0);
    setSelectedSkills([]);
    setSubSkills([]);
  };

  //Aqui limpa e fecha o modal formulario
  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleCancel} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Aulas</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                <strong>Nome da Aula</strong>
              </Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                <strong>Descrição da Aula</strong>
              </Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                <strong>Link do Youtube</strong>
              </Form.Label>
              <Form.Control
                value={linkYoutube}
                onChange={(e) => setLinkYoutube(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                <strong>Duração (segundos)</strong>
              </Form.Label>
              <Form.Control
                value={duration}
                onChange={(e) => setDuration(Number(e.target.value))}
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                <strong>Ordem</strong>
              </Form.Label>
              <Form.Control
                value={order}
                onChange={(e) => setOrder(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
          <br />

          <Modal.Title>
            <strong>Skills</strong>
          </Modal.Title>
          <br />

          <Form className="skills">
            {skills.map((skill, index) => (
              <React.Fragment key={index}>
                <Form.Group
                  className="mb-3"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <Form.Label style={{ marginRight: "10px", flex: 1 }}>
                        <strong>{skill.name}</strong>
                      </Form.Label>
                      <Form.Control
                        value={
                          selectedSkills.find((s) => s.id === skill.id)
                            ?.value || ""
                        }
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            const listSkills = [...selectedSkills];
                            const existingSkill = listSkills.find(
                              (item) => item.id === skill.id
                            );

                            if (existingSkill) {
                              existingSkill.value = Number(e.target.value);
                            } else {
                              listSkills.push({
                                id: skill.id,
                                value: Number(e.target.value),
                                subSkills: [],
                              });
                            }

                            setSelectedSkills(listSkills);
                          }
                        }}
                        style={{ marginRight: "10px", flex: 5 }}
                      />
                      <Button
                        variant="primary"
                        onClick={() => handleSubSkillsClick(skill.id)}
                        style={{ flex: 1 }}
                      >
                        SubSkills
                      </Button>
                    </Col>
                  </Row>

                  <Stack direction="horizontal" gap={2} className="mt-2">
                    {subSkills.map(
                      (subSkill) =>
                        subSkill.idSkill === skill.id &&
                        subSkill.selected && (
                          <Badge
                            key={subSkill.id}
                            style={{ fontSize: "15px", background: "#fff" }}
                          >
                            {subSkill.description}
                          </Badge>
                        )
                    )}
                  </Stack>
                </Form.Group>
                {index < skills.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </Form>

          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Aula já cadastrada
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={handleClick}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubSkillModal}
        onHide={() => setShowSubSkillModal(false)}
        size="xl"
      >
        <Modal.Header closeButton style={{ backgroundColor: "rgba(0, 255, 0, 0.1)" }}>
          <Modal.Title>SubSkills</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "rgba(0, 255, 0, 0.1)" }}>
          <Form>
            {subSkills.map((subSkill, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                id={`subSkill-${subSkill.id}`}
                label={subSkill.description}
                checked={subSkill.selected || false}
                onChange={() => handleSubSkillSelection(subSkill.id)}
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "rgba(0, 255, 0, 0.1)" }}>
          <Button
            variant="secondary"
            onClick={() => setShowSubSkillModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveSubSkill}
            style={{ marginLeft: "10px" }}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalLessons;
