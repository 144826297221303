import { Button, TextField, CircularProgress } from "@material-ui/core";
import "./index.scss";
import ICreateComment from "../../interfaces/ICreateComment";
import { useState } from "react";
import lessonService from "../../services/LessonService";
import { ICommentResponse, IQuestionWithComments } from "../../interfaces/ICommentResponse";
import { Modal } from "react-bootstrap";

const CommentForm = (props: {
  question?: IQuestionWithComments; // Atualizado para ICommentResponse
  callback: () => void;
  closecomment: () => void;
  visible: boolean;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");

  const clearFields = () => {
    setText("");
  };

  const sendComment = () => {
    if (!props.question) return;
    setLoading(true); // Ativa o carregamento
    let data: ICreateComment = {
      question: props.question.id,
      text: text,
    };

    lessonService
      .comment(data)
      .then((response) => {
        setLoading(false);
        clearFields();
        props.callback();
        props.closecomment();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Modal show={props.visible} onHide={props.closecomment} size="xl">
      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
      <form>
        <div className="textArea2">
          <TextField
            name="text"
            type="text"
            placeholder="Escreva sua resposta aqui"
            multiline
            required
            autoFocus
            rows={4}
            variant="outlined"
            className="textField"
            onChange={(e) => setText(e.target.value)}
            value={text}
            style={{ width: '95%', marginTop: '30px', marginLeft: '2%', boxShadow: '5px 3px 3px black' }}
            disabled={isLoading} // Desativa enquanto carrega
          />
        </div>

        <div className="enviar2">
          <Button
            className="button-confirm"
            variant="contained"
            onClick={sendComment}
            style={{ backgroundColor: '#0f00e5', width: '100%', marginTop: 10, color: "white" }}
            disabled={isLoading} // Desativa enquanto carrega
          >
            {isLoading ? <CircularProgress size={24} /> : "Enviar"}
          </Button>
        </div>
        <div className="cancelar">
          <Button
            className="button-cancel"
            variant="contained"
            onClick={props.closecomment}
            style={{ backgroundColor: 'rgb(235, 37, 37)', color: "white", marginTop: 10 }}
            disabled={isLoading} // Desativa enquanto carrega
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CommentForm;
